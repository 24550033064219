import React from "react";
import Layout from "../components/layout";
import {  graphql} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const SubCatalogTemplate = ({ data }) => {

  const { type } = data.strapiCategory;
  return (
    <Layout>
      <div className="content_header text-center mt-60 mb-60 container">
        <h1>{type} DENDOR</h1>
        <div className="product mt-60">
          {data.allStrapiProduct.nodes.map((item) => {
            const { slug, name, image, short_description,/*  categories */ } = item;
            let slugWithCategory = slug;
            const img = getImage(image[0].localFile.childImageSharp);
            return (
              <div key={slug} className="product__item">
                <div className="product__body">
                  <a href={slugWithCategory}>
                    <GatsbyImage image={img} alt={name} />
                  </a>
                </div>
                <div className="product__header">
                  <a href={slugWithCategory}>{name}</a>
                  <p className="mt-15">{short_description}</p>
                </div>
                <div className="product__footer">
                  <a className="product_btn" href={slugWithCategory}>
                    Подробнее
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default SubCatalogTemplate;
export const subCatalogtQuery = graphql`
  query subCatalog($slug: String) {
    allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        slug
        strapi_id
        name
        short_description
        categories {
          slug
          id_category
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 250, backgroundColor: "transparent")
            }
          }
        }
      }
    }
    strapiCategory(slug: { eq: $slug }) {
      type
    }
  }
`;
